import { useEffect } from 'react';
type Props = {
  url: string;
};
export const EmbedInstagram = ({
  url
}: Props) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.setAttribute('src', 'https://www.instagram.com/embed.js');
    document.getElementsByClassName('instagram-media')[0]?.appendChild(script);
  }, [url]);
  return <div data-sentry-component="EmbedInstagram" data-sentry-source-file="EmbedInstagram.tsx">
			<blockquote className="instagram-media" data-instgrm-permalink={url} data-instgrm-version="13" style={{
      maxWidth: '540px'
    }}></blockquote>
		</div>;
};