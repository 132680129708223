import { forwardRef } from 'react';
type Props = {
  url: string;
  width?: string;
  height?: string;
};
export const EmbedSpotify = forwardRef(function EmbedSpotify({
  url,
  width = '100%',
  height = '380'
}: Props) {
  if (!url) return null;
  return <iframe src={url} width={width} height={height} allowTransparency={true} allow="encrypted-media"></iframe>;
});