import { forwardRef, useEffect } from 'react';
import clsx from 'clsx';
type Props = {
  className?: string;
  url?: string;
  aspectRatio?: number;
  frameBorder?: string;
  scrolling?: string;
  allowFullScreen?: boolean;
  width?: string | number;
  height?: string | number;
  borderStyle?: string; // added new prop
};
export const EmbedIframe = forwardRef<HTMLDivElement, Props>(function EmbedIframe({
  className,
  url,
  aspectRatio,
  allowFullScreen = false,
  width,
  height,
  borderStyle
}: Props,
// added new prop
ref) {
  useEffect(() => {
    if (!url) {
      console.error('URL must be provided for EmbedIframe component.');
    }
  }, [url]);
  const renderIframe = () => {
    if (width && height) {
      return <iframe src={url} allowFullScreen={allowFullScreen} className={clsx('embed_iframe relative')} style={{
        width: `${width}`,
        height: `${height}`,
        border: borderStyle
      }} // use borderStyle prop
      ></iframe>;
    } else {
      return <div className="responsive_padding relative" style={{
        paddingTop: `${100 / (aspectRatio || 16 / 9)}%`
      }}>
					<div className="responsive_wrapper absolute inset-0">
						<iframe src={url} allowFullScreen={allowFullScreen} className={clsx('embed_iframe relative h-full w-full')} style={{
            border: borderStyle
          }} // use borderStyle prop
          ></iframe>
					</div>
				</div>;
    }
  };
  return <div className={className} ref={ref}>
			{url ? renderIframe() : <div className="p-5 w-full text-center text-lg bg-yellow-300 rounded-lg">
					Cannot embed content. Please provide a valid URL.
				</div>}
		</div>;
});